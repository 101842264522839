<template>
  <div>
    <section class="section-hero sc-onboarding">
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-md-12 col-xl-8 mb-3">
            <RestaurantInformationCard :first-time="true"/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import RestaurantInformationCard from "@/components/RestaurantInformationCard";

export default {
  name: "CompleteSettings",
  components: {RestaurantInformationCard}
}
</script>
